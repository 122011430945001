import React from "react";
import "./VibeHeader.scss";
import vibe from "../../../../assets/images/vibe-light-logo.png";

const VibeHeader = ({ product }) => {
  return (
    <>
      <div className="vibe-redemption-code-header">
        <img src={vibe} alt="Vibe" />
      </div>
    </>
  );
};

export default VibeHeader;
