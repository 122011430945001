import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import random from "../../../../utils/random";
import logo from "../../../../assets/images/shophub-logo.png";
import React from "react";

const MkpSkeletonContainer = () => {
  return (
    <>
      <div className="redemption-page-container">
        <section className="redemption-card">
          <div className="card-container">
            <div className="voucher-image">
              <Skeleton width={260} height={165} />
            </div>
            <h1 className="voucher-name">
              <Skeleton width={random(100, 200)} />
            </h1>
            <h2 className="voucher-price">
              <Skeleton width={200} />
            </h2>
          </div>

          <div className="folding">
            <hr />
          </div>

          <div className="card-container">
            <div className="px-4">
              <Skeleton width="100%" height={126} />
            </div>
          </div>
        </section>
      </div>
      <section className="redemption-footer">
        <div className="container">
          <div className="d-flex flex-column flex-lg-row">
            <article className="redemption-footer-container flex-grow-1">
              {Array(random(2, 3))
                .fill()
                .map((_, i) => (
                  <React.Fragment key={i}>
                    <p key={`title-${i}`}>
                      <Skeleton width={random(30, 100) + "%"} />
                    </p>
                    <p key={`desc-${i}`}>
                      {Array(random(3, 5))
                        .fill()
                        .map((_, i2) => (
                          <Skeleton
                            key={`desc-${i}-${i2}`}
                            width={random(30, 100) + "%"}
                          />
                        ))}
                    </p>
                  </React.Fragment>
                ))}
            </article>
            <aside className="text-center my-4 my-lg-0">
              <img src={logo} alt="shophub" />
            </aside>
          </div>
        </div>
      </section>
    </>
  );
};

export default MkpSkeletonContainer;
