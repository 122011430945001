const ENDPOINTS = {
  revealVoucher: (h, c, recaptchaValue) => ({
    url: `order/code`,
    method: "GET",
    headers: recaptchaValue
      ? { recaptcha: recaptchaValue }
      : { "Skip-Recaptcha": "true" },
    params: { h, c },
  }),
};

export default ENDPOINTS;
