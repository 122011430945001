import React from "react";
import "./MkpFooter.scss";
import logo from "../../../../assets/images/shophub-logo.png";

const MkpFooter = ({ product }) => {
  return (
    <section className="redemption-footer">
      <div className="container">
        <div className="d-flex flex-column flex-lg-row">
          <article className="redemption-footer-container flex-grow-1">
            {Object.entries(product.features).map(([t, v]) => (
              <React.Fragment key={t}>
                <p>
                  <strong>{t}</strong>
                </p>
                <p>
                  <div dangerouslySetInnerHTML={{ __html: v }}></div>
                </p>
              </React.Fragment>
            ))}
          </article>
          <aside className="text-center my-4 my-lg-0">
            <img src={logo} alt="shophub" />
          </aside>
        </div>
      </div>
    </section>
  );
};

export default MkpFooter;
