import React from "react";
import "./LigaHeader.scss";
import liga from "../../../../assets/images/Logo_LIGA_Cinza.svg";

const LigaHeader = ({ product }) => {
  return (
    <>
      <div className="redemption-code-header">
        <img src={liga} alt="liga"/>
      </div>
    </>
  );
};

export default LigaHeader;
