import FamilhaoHeader from "./FamilhaoHeader";
import FamilhaoFooter from "./FamilhaoFooter";
import VoucherCode from "../../../../components/VoucherCode";
import "./FamilhaoContainer.scss";
import './FamilhaoVoucherCode.scss'

const FamilhaoContainer = ({ product }) => {
  return (
    <>
      <section className="redemption-code-container">
        <FamilhaoHeader />
        <div className="redemption-code-content row justify-content-center">
          <div className="card redemption-code-card ">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <div className="voucher-image-redemption-code">
                    <img
                      src={product.image?.medium || product.image?.original}
                      alt={product.name}
                    />
                  </div>
                </div>
                <div className="col-sm-8 align-self-center">
                  <p className="voucher-name">{product.name}</p>
                  <p className="voucher-price">{product.productDescription}</p>
                </div>
              </div>
              <VoucherCode
                code={product.codes.voucherCode}
                pin={product.codes.voucherPinCode}
                merchantId={product.merchantId}
                link={product.codes.link}
                showCodeMessage="Ver meu código"
              />
            </div>
            <div className="card-footer">
              Válido para utilização exclusiva no App.
            </div>
          </div>
        </div>
        <FamilhaoFooter />
      </section>
    </>
  );
};

export default FamilhaoContainer;
