import React from "react";
import "./MkpContainer.scss";
import VoucherCode from "../../../../components/VoucherCode";
import MkpFooter from "./MkpFooter";
import formatCurrency from "../../../../utils/formatCurrency";
import "./MkpVoucherCode.scss";

const RedemptionContainer = ({ product }) => {
  return(<>
        <div className="redemption-page-container">
            <section className="redemption-card">
              <div className="card-container">
                <div className="voucher-image">
                  <img
                    src={product.image?.medium || product.image?.original}
                    alt={product.name}
                  />
                </div>
                <h1 className="voucher-name">{product.name}</h1>
                <h2 className="voucher-price">{formatCurrency(product.price)}</h2>
              </div>

              <div className="folding">
                <hr />
              </div>

              <div className="card-container">
                  <div className="voucher-code">
                      <div className="voucher-code-body">
                        <h3>CÓDIGO DO VALE</h3>
                        <VoucherCode
                          code={product.codes.voucherCode}
                          pin={product.codes.voucherPinCode}
                          merchantId={product.merchantId}
                          link={product.codes.link}
                          showCodeMessage="************************"
                        />
                      </div>
                  </div>
              </div>
            </section>
        </div>
        <MkpFooter product={product} />
      </>
  )
};

export default RedemptionContainer;
