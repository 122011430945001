import React from "react";
import "./FamilhaoHeader.scss";
import familhao from "../../../../assets/images/familhao-logo.png";

const FamilhaoHeader = ({ product }) => {
  return (
    <>
      <div className="redemption-code-header">
        <img src={familhao} alt="Familhão" />
      </div>
    </>
  );
};

export default FamilhaoHeader;
