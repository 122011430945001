import React from "react";
import { useSearchParams } from "react-router-dom";
import logo from "../../assets/images/shophub-logo.png";
import familhaoLogo from "../../assets/images/familhao-logo.png";
import vibeLogo from "../../assets/images/vibe-logo.svg";
import ligaLogo from "../../assets/images/logo-liga-black.svg";
import Customers from "../../utils/consts";

export const Layout = ({ children }) => {
  let [searchParams] = useSearchParams();
  const supplierId = searchParams.get("s") ?? 0;
  const supplier = SupplierCustomization(supplierId);

  return (
    <main className="sh-main d-flex flex-column">
      <section className="sh-content flex-grow-1 d-flex">{children}</section>
      <footer className="sh-footer">
        <div className="container">
          <div className="d-flex flex-column flex-lg-row align-items-center">
            <article className="redemption-footer-container flex-grow-1">
              © 2022 Shophub. All rights reserved.
            </article>
            <aside className="text-center my-4 my-lg-0">
              <img src={supplier.logo} alt={supplier.name} />
            </aside>
          </div>
        </div>
      </footer>
    </main>
  );
};

export const SupplierCustomization = (supplierId) => {
  let supplier = {};
  switch (supplierId) {
    case Customers.FAMILHAO:
      supplier.logo = familhaoLogo;
      supplier.name = "Familhão";
      break;
    case Customers.VIBE:
      supplier.logo = vibeLogo;
      supplier.name = "vibe";
      break;
    case Customers.LIGA:
        supplier.logo = ligaLogo;
        supplier.name = "liga";
        break;  
    default:
      supplier.logo = logo;
      supplier.name = "shophub";
  }

  return supplier;
};
