import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./vibe/VibeContainer.scss";

const SkeletonContainer = () => {
  return (
    <>
      <section className="redemption-code-container">
        <Skeleton width={600} height={100} />
        <div className="redemption-code-content  justify-content-center">
          <div className="redemption-code-container">
            <Skeleton width={470} height={257} />
          </div>
        </div>
        <Skeleton width={600} height={235} />
      </section>
    </>
  );
};

export default SkeletonContainer;
