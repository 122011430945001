import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import React from "react";
import Redemption from "../pages/redemption";
import NotFound404 from "../pages/notFound404";
import Layout from "../components/Layout";
import VerifyYourIdentityPage from "../pages/verifyYourIdentity";


const RedirectTo = ({ to }) => {
    const location = useLocation();
    const toWithQuery = `${to}${location.search}`;
    return <Navigate to={toWithQuery} replace />;
};

export const AppRoutes = () => {
    return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              {/* <Home /> */}
              <NotFound404 />
            </Layout>
          }
        />

        <Route
          path="/verify-your-identity"
          element={
            <Layout>
              <VerifyYourIdentityPage />
            </Layout>
          }
        />
        <Route path="/redemption" element={<Redemption />} />
        <Route path="/vibe/redemption" element={<RedirectTo to="/redemption" />} />
        <Route path="/redemption-code" element={<RedirectTo to="/redemption" />} />
        <Route
          path="*"
          element={
            <Layout>
              <NotFound404 />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
