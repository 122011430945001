import VibeHeader from "./VibeHeader";
import VibeFooter from "./VibeFooter";
import VoucherCode from "../../../../components/VoucherCode";
import "./VibeContainer.scss";
import "./VibeVoucherCode.scss"

const VibeContainer = ({ product }) => {
  return (
    <>
      <section className="redemption-code-container">
        <VibeHeader />
        <div className="vibe-redemption-code-content row justify-content-center">
          <div className="card vibe-redemption-code-card-top ">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <div className="voucher-image-redemption-code">
                    <img
                      src={product.image?.medium || product.image?.original}
                      alt={product.name}
                    />
                  </div>
                </div>
                <div className="col-sm-8 align-self-center">
                  <p className="voucher-name">{product.name}</p>
                  <p className="voucher-price">{product.productDescription}</p>
                </div>
              </div>
              <VoucherCode
                code={product.codes.voucherCode}
                pin={product.codes.voucherPinCode}
                merchantId={product.merchantId}
                link={product.codes.link}
                showCodeMessage="Ver meu código"
              />
            </div>
          </div>
          <div className="card vibe-redemption-code-card-bottom justify-content-center ">
            <div className="card-body align-self-center">
              <div className="vibe-footer">
                Válido para utilização exclusiva no App.
              </div>
            </div>
          </div>
        </div>
        <VibeFooter />
      </section>
    </>
  );
};

export default VibeContainer;
