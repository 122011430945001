import React from "react";
import opsImage from "../../assets/images/ops.svg"

export const NotFound404 = () => {
  return (
    <div className="container py-5 d-flex justify-content-center align-items-center">
      <figure className="text-center">
        <img src={opsImage} alt="OPS!" className="mb-5 img-fluid" />
        <h1><strong>Página não encontrada.</strong></h1>
        <legend className="sh-text-dark">Que pena, parece que o voucher não esta mais disponível</legend>
      </figure>
    </div>
  );
};
