import axios from "./axios";

const API = ({
  url,
  method,
  responseType,
  data,
  cancelToken,
  params,
  headers,
}) => {
  const axiosRequestObject = {
    method,
    url,
    data,
    headers,
    responseType,
    params,
    ...(cancelToken
      ? {
          cancelToken,
        }
      : ""),
  };

  return axios.request(axiosRequestObject).then(handleSuccessRequest);
};

const handleSuccessRequest = ({ status, data }) => ({ status, data });


export default API;