import liga from "../../../../assets/images/Logo_LIGA_Cinza.svg";
import "./LigaFooter.scss";

const LigaFooter = () => {
  return (
    <>
      <div className="redemption-code-sub-footer"></div>
      <div className="redemption-code-footer flex-column">
        <div className="row justify-content-between">
          <div className="redemption-code-footer-logo col-sm">
            <img src={liga} alt="mi" />
          </div>
          <div className="redemption-code-footer-social col-md">
            Acesse: liga.site
          </div>
        </div>

        <div className="footer-line row"></div>
        <div className="footer-address row">
          <span>
          Alameda Rio Negro, 1030 - Cond. Stadium Escrit. 206 - Barueri - SP
          </span>
          <div className="footer-links">
            <span>
              {" "}
              <a target="_blank" href="#" rel="noreferrer">
                Descadastrar-se
              </a>{" "}
              |
              <a target="_blank" href="#" rel="noreferrer">
                Política de Privacidade
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LigaFooter;
