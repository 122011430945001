const formatCurrency = (value) => {
    if (value) {
        const locale = Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
        return locale.format(value);
    }
    return "";
};

export default formatCurrency;