import vibe from "../../../../assets/images/vibe-light-logo.png";
import socialNetworks from "../../../../assets/images/group-social.svg";
import "./VibeFooter.scss";

const VibeFooter = () => {
  return (
    <>
      <div className="redemption-vibe-footer flex-column">
        <div className="row justify-content-between">
          <div className="redemption-vibe-footer-logo col-sm">
            <img src={vibe} alt="vibe" max-width="66px" width="66px" />
          </div>
          <div className="redemption-vibe-footer-social col-md">
            <img src={socialNetworks} alt="vibe" />
          </div>
        </div>

        <div className="footer-line row"></div>
        <div className="footer-address row">
          <span>
            Alameda Rio Negro, 585, 13º Andar. Alphaville, Barueri, SP. CEP
            06454-000.
          </span>
          <div className="footer-links">
            <span>
              {" "}
              <a target="_blank" href="#" rel="noreferrer">
                Descadastrar-se
              </a>{" "}
              |
              <a target="_blank" href="#" rel="noreferrer">
                Política de Privacidade
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default VibeFooter;
