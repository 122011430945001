import { useState } from "react";

export const ContentCopy = ({ text, children }) => {
  if (!text) text = children.toString();

  const [copied, setCopied] = useState(false);
  const [copiedTimeoutId, setCopiedTimeoutId] = useState(0);

  const handleCopyClick = (e) => {
    e.preventDefault();
    if (copied) return;

    setCopied(true);
    navigator.clipboard.writeText(text).finally(performCopiedSuccessfully);
  };

  const handleMouseOut = () => {
    if (copiedTimeoutId) {
      clearTimeout(copiedTimeoutId);
      setTimeout(() => {
        setCopied(false);
      }, 300);
    }
  };

  const performCopiedSuccessfully = () => {
    const id = setTimeout(() => setCopied(false), 2000);
    setCopiedTimeoutId(id);
  };

  return (
    <span
      className="content-copy"
      onClick={handleCopyClick}
      onMouseOut={handleMouseOut}
    >
      {copied ? (
        "Copiado!"
      ) : (
        <span style={{ cursor: "pointer" }}>{children}</span>
      )}
    </span>
  );
};
