import { useState, useEffect } from "react";
import yn from "yn";
import { useSearchParams } from "react-router-dom";
import { Button } from "reactstrap";
import verifyYourIdentityImg from "../../assets/images/verify-your-identity.svg";
import { Turnstile } from "@marsidev/react-turnstile";
import Customers from "../../utils/consts";

export const VerifyYourIdentityPage = () => {
  let [searchParams] = useSearchParams();

  const h = searchParams.get("h");
  const c = searchParams.get("c");
  const s = searchParams.get("s");

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const useRecaptcha = yn(process.env.REACT_APP_SERVICE_USE_RECAPTCHA);

  const handleRecaptcha = (token) => setRecaptchaToken(token);

  const supplierAction = (supplierId) => {
    let url = "";

    switch (supplierId) {
      case Customers.FAMILHAO:
        url = "/redemption-code";
        break;
      case Customers.VIBE:
        url = "/vibe/redemption";
        break;
      default:
        url = "/redemption";
    }

    return url;
  };

  return (
    <div className="container py-5 d-flex flex-column justify-content-center align-items-center">
      <figure className="text-center">
        <img
          src={verifyYourIdentityImg}
          alt="Verifique sua identidade"
          className="mb-5 img-fluid"
        />
        <h1>
          <strong>Verifique sua identidade</strong>
        </h1>
        <legend className="sh-text-dark">
          Para continuar e aproveitar o seu voucher, faça a verificação.{" "}
          {useRecaptcha}
        </legend>
      </figure>

      {useRecaptcha && (
        <div className="my-3">
          <Turnstile
            siteKey={process.env.REACT_APP_SERVICE_RECAPTCHA_KEY}
            onSuccess={handleRecaptcha}
            style={{ display: "inline-block" }}
            onError={(err) => console.log(err)}
          />
        </div>
      )}

      <form method="GET" action={supplierAction(s)}>
        <input
          type="hidden"
          name="recaptcha-token"
          value={recaptchaToken ?? ""}
        />
        <input type="hidden" name="h" value={h} />
        <input type="hidden" name="c" value={c} />
        <input type="hidden" name="s" value={s} />
        <Button
          color="secondary"
          disabled={!recaptchaToken && useRecaptcha}
          style={{ width: 305 }}
        >
          QUERO MEU VOUCHER
        </Button>
      </form>
    </div>
  );
};
